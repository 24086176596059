import { css } from "styled-components"

export default css`
  body {
    font-family: Arial;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "liga", "kern";
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  ::selection {
    background: rgba(0, 185, 228, 0.4);
  }
`

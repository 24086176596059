export default {
  blue: "#00B9E4",
  green: "#00A599",
  pink: "#E21776",
  grey: "#F7F7F7",
  orange: "#FFA100",
  black: "#303030",
  white: "#FFFFFF",
  red: "#E75020",
}

import React, { useRef, useState } from "react"
import { ThemeProvider } from "styled-components"
import Theme from "../../style/theme"
import GlobalStyle from "../../style/GlobalStyle"
import Grid from "../grid/Grid-Marker"
import smoothscroll from "smoothscroll-polyfill"
import CookieConsent from "react-cookie-consent"
import styled from "styled-components"

import Header from "../header/Header"
{
  typeof window !== "undefined" && smoothscroll.polyfill()
}

const Layout = ({ children, stories }) => {
  const [showMenu, setShowMenu] = useState(null)
  const openMenu = value => {
    setShowMenu(!showMenu)
  }
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, { openMenu: openMenu })
  })

  return (
    <>
      <Grid />
      <GlobalStyle />
      <ThemeProvider theme={Theme}>
        <Header showMenuP={showMenu} setShowMenuP={openMenu} />
        {childrenWithProps}
      </ThemeProvider>
      <CookieConsent>
        <CookieInner>
          <span>This website uses cookies to enhance the user experience.</span>
          <a href="https://www.seemescotland.org/privacy/">More details</a>
        </CookieInner>
      </CookieConsent>
    </>
  )
}

const CookieInner = styled.div`
  span,
  a {
    color: #fff;
  }

  a {
    margin-left: 5px;
    text-decoration: underline;
  }
`

export default Layout

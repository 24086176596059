export default {
  tiny: `4px`,
  xxs: `8px`,
  xs: `12px`,
  s: `16px`,
  m: `20px`,
  l: `24px`,
  xl: `32px`,
  xxl: `40px`,
  mega: `60px`,
}

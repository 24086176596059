const sizes = {
  small: 320,
  medium: 480,
  large: 768,
  largest: 1024,
  xlarge: 1440,
  xxlarge: 1800,
}

export const media = {
  small: `@media (max-width: ${sizes.small}px)`,
  medium: `@media (max-width: ${sizes.medium}px)`,
  large: `@media (max-width: ${sizes.large}px)`,
  largest: `@media (max-width: ${sizes.largest}px)`,

  xlarge: `@media (min-width: ${sizes.xlarge}px)`,
  xxlarge: `@media (min-width: ${sizes.xxlarge}px)`,

  hover: `@media (hover: hover)`,
  custom: px => {
    return `@media (max-width: ${px}px)`
  },
}

import React, { useRef, useState } from "react"
import styled from "styled-components/macro"

import CommaIcon from "../../images/icons/comma.svg"

const Comma = props => {
  return <CommaIcon {...props} />
}

export default Comma

import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components/macro"
import { desktop, mobile } from "../../style/grid"
import Grid from "../../style/grid"
import { media } from "../../style/breakpoints"

const GridTrack = ({}) => {
  const [keyPressed, setKeyPressed] = useState(false)
  let targetKey = "g"

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(oldState => !oldState)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", upHandler)
    return () => {
      window.removeEventListener("keydown", upHandler)
    }
  }, [])

  if (!keyPressed) {
    return <></>
  }

  let columnsDesk = []
  for (let i = 0; i < desktop.gridCount; i++) {
    columnsDesk.push(<div></div>)
  }

  let columnsMobile = []
  for (let i = 0; i < mobile.gridCount; i++) {
    columnsMobile.push(<div></div>)
  }

  return (
    <Wrapper>
      <div className="mobile">
        <Wrapper columns={columnsMobile}>{columnsMobile}</Wrapper>
      </div>
      <div className="desktop">
        <Wrapper columns={columnsDesk}>{columnsDesk}</Wrapper>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  display: flex;
  justify-content: space-between;
  padding: 0 ${Grid.desktop.outerGutter};
  > div {
    width: ${Grid.desktop.colWidth};
    background: blue;
    opacity: 0.3;
  }

  .mobile {
    display: none;
  }

  ${media.largest} {
    padding: 0 ${Grid.mobile.outerGutter};
    > div {
      width: ${Grid.mobile.colWidth};
    }
    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }
  }
`

export default GridTrack

import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { TimelineMax } from "gsap"
import { Link } from "gatsby"

import logo1x from "../../images/Logo/Logo.png"
import logo2x from "../../images/Logo/Logo@2x.png"
import Audio from "../../images/icons/audio-solid.svg"
import Bars from "../../images/icons/menu.svg"

import StoryMenu from "../story-menu/StoryMenu"

const Header = ({ showMenuP, setShowMenuP }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const _ctaButton = useRef()

  useEffect(() => {
    setShowMenu(showMenuP)
  }, [showMenuP])

  const tl = new TimelineMax({ paused: false, id: "myTimeline" })

  useEffect(() => {
    if (showMenu) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }

    if (loaded) {
      return
    }

    setTimeout(() => {
      tl.fromTo(
        _ctaButton.current,
        2.5,
        { opacity: 0 },
        {
          opacity: 1,
        }
      )
      setLoaded(true)
    }, 2000)
  }, [showMenu])

  return (
    <Wrapper active={showMenu}>
      <Inner>
        <Menu>
          <div>
            <div>
              <BarsComp
                onClick={() => {
                  setShowMenu(!showMenu)
                  setShowMenuP(!showMenu)
                }}
                active={showMenu}
              />
            </div>
          </div>
        </Menu>
        <Logo>
          <Link to="/">
            <img
              src={logo1x}
              srcSet={logo1x + " 1x," + logo2x + " 2x"}
              alt={"SeeMe"}
            />
          </Link>
        </Logo>

        <CTA
          ref={_ctaButton}
          onClick={() => {
            ga("send", "event", "Report - Start your journey")
          }}
        >
          <a href="https://www.seemescotland.org/resources/" target="_blank">
            <div>
              <span>Start your journey</span>
            </div>
          </a>
        </CTA>
      </Inner>
      <StoryMenu active={showMenu} />
      <Thick left={showMenu} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  height: 119px;

  ${media.largest} {
    height: 95px;
    z-index: 9999;

    ${props =>
      props.active &&
      `
      > div {
        background: ${props.theme.colors.blue};
      }
    `}
  }

  &:before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1px;
    right: 0;
    z-index: 5;
    background: rgba(255, 255, 255, 0.25);
  }
`

const Inner = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 0 ${props => props.theme.grid.desktop.outerGutter};
  height: 119px;
  z-index: 5;
  position: absolute;
  ${media.largest} {
    flex-direction: row-reverse;
    height: 93px;
    padding: 0;
  }
`

const Logo = styled.div`
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 7px 0 8px;

  img {
    width: 130px;
  }

  ${media.largest} {
    position: static;
    transform: translateX(0%);
    text-align: left;
    margin-left: ${props => props.theme.grid.mobile.outerGutter};
    img {
      width: 105px;
    }
  }
`

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  div {
    background: #fff;
    border-radius: 5px;
    padding: ${props => props.theme.sizing.s} ${props => props.theme.sizing.l};
    cursor: pointer;
    /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); */
    ${props => props.theme.transitions.default("all")};
    border: 2px solid #fff;

    span {
      ${props => props.theme.typography.body};
      color: ${props => props.theme.colors.black};
      ${props => props.theme.transitions.default("color")};
    }
  }

  ${media.largest} {
    display: none;
  }

  ${media.hover} {
    &:hover {
      div {
        background: transparent;
        border: 1px solid #fff;
      }
      span {
        color: #fff;
      }
    }
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    display: flex;
  }

  ${media.largest} {
    flex-basis: 135px;
    > div {
      flex-direction: row-reverse;
      height: 100%;
    }
    div div {
      border-left: 1px solid rgba(255, 255, 255, 0.25);
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 75px;
    }
  }

  svg {
    margin-right: ${props => props.theme.sizing.xxl};
    cursor: pointer;
    ${media.largest} {
      margin: 0 auto;
    }
  }
`

const BarsComp = styled(Bars)`
  transform: rotate(0deg);
  ${props => props.theme.transitions.default("transform")};
  ${props =>
    props.active &&
    `
    transform: rotate(90deg);

`}
`

const Thick = styled.div`
  height: 3px;
  background: #fff;
  width: 140px;
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);
  ${props => props.theme.transitions.default("all")};
  ${props =>
    props.left &&
    `
    transform: translateX(0%);
    left:0;

`}

  ${media.largest} {
    left: 0;
    transform: translateX(0%);
    width: 160px;
  }
`

export default React.memo(Header)

import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { StaticQuery, graphql, Link } from "gatsby"
import { media } from "../../style/breakpoints"
import { RichText } from "prismic-reactjs"

import CTA from "../../images/icons/cta.svg"

const storiesQuery = graphql`
  query {
    prismic {
      allStorys(sortBy: meta_lastPublicationDate_DESC) {
        edges {
          node {
            author
            illustration
            introduction
            key_color
            name
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const StoryMenu = ({ active, className }) => (
  <StaticQuery
    query={storiesQuery}
    render={data => {
      const stories = data.prismic.allStorys.edges
      return (
        <StoryMenuInner
          stories={stories}
          active={active}
          className={className}
        />
      )
    }}
  />
)

const StoryMenuInner = ({ stories, active, className }) => {
  stories = stories.filter(story => story.node._meta.uid !== "see-me")
  return (
    <Wrapper active={active} className={className}>
      <Inner>
        {stories.map(story => {
          return (
            <StorySlice keyColor={story.node.key_color} number={stories.length}>
              <Link to={`/${story.node._meta.uid}`}>
                <Image>
                  <img src={story.node.illustration.url} alt="" />
                  <Author>{story.node.author[0].text}</Author>
                  <Title>{story.node.name[0].text}</Title>
                  {/* <RichText render={story.node.introduction} /> */}
                  <CTA />
                </Image>
              </Link>
            </StorySlice>
          )
        })}
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(0%);
  opacity: 1;
  ${props => props.theme.transitions.default("opacity")};

  ${media.largest} {
    display: block;
    overflow-y: scroll;
  }

  ${props =>
    !props.active &&
    `
    opacity: 0;
    transform: translateY(-100%)
  `}
`

const Inner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${media.largest} {
    display: block;
  }
`

const StorySlice = styled.div`
  background: ${props => props.keyColor};
  display: flex;
  margin: auto;
  position: relative;
  flex: 1;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  color: white;
  transition: 0.3s;
  max-width: ${props => `${100 / props.number}%`};
  height: 100vh;
  min-width: 5%;
  overflow: hidden;

  ${media.largest} {
    display: block;
    min-width: 100%;
    max-width: 100%;
    padding: ${props => props.theme.sizing.mega}
      ${props => props.theme.grid.mobile.outerGutter}
      ${props => props.theme.sizing.mega};
    height: auto;
    h1,
    h2 {
      white-space: normal !important;
    }

    &:nth-child(1) {
      padding: calc(${props => props.theme.sizing.mega} + 95px)
        ${props => props.theme.grid.mobile.outerGutter}
        ${props => props.theme.sizing.mega};
    }
  }

  h1,
  h2 {
    opacity: 0;
    white-space: nowrap;
    transition: 0.3s;
    ${media.largest} {
      opacity: 1;
      text-align: left;
    }
  }

  ${media.hover} {
    &:hover {
      max-width: 100% !important;
      flex-grow: 7.5;

      h1,
      h2,
      p {
        opacity: 1;
      }
    }
  }
`

const Image = styled.div`
  img {
    max-height: 150px;
    margin-bottom: ${props => props.theme.sizing.mega};
  }
  svg {
    display: none;
  }
  ${media.largest} {
    text-align: left;
    img {
      margin-bottom: ${props => props.theme.sizing.m};
      max-height: 100px;
    }
    svg {
      display: block;
      margin-top: ${props => props.theme.sizing.m};
      path {
        fill: #fff;
      }
    }
  }

  p {
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.white};
    opacity: 0;
    width: 500px;
    margin: 0 auto;
    text-align: center;
    ${media.largest} {
      width: 100%;
      opacity: 1;
      text-align: left;
    }
  }
`

const Author = styled.h2`
  ${props => props.theme.typography.smallTitle};
  color: ${props => props.theme.colors.white};
`

const Title = styled.h1`
  ${props => props.theme.typography.h2};
  color: ${props => props.theme.colors.white};
`

export default StoryMenu

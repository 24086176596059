import Colors from "./colors"
import Sizing from "./sizing"
import Grid from "./grid"
import { style } from "./typography/typography"
import Transitions from "./transitions"

export default {
  colors: Colors,
  sizing: Sizing,
  grid: Grid,
  typography: style,
  transitions: Transitions,
}

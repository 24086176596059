import { css } from "styled-components"
import { media } from "../breakpoints"
import TypographyUtils from "./TypographyUtlis"

export default css`
  * {
    font-family: Open Sans, sans-serif;
    color: #000;
  }

  html {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }
`

let style = {
  h1: `
      ${TypographyUtils.fontSizeCalc(
        TypographyUtils.convertPixelToEm(250),
        TypographyUtils.convertPixelToEm(100)
      )};
      ${TypographyUtils.lineHeightCalc(
        TypographyUtils.convertPixelToEm(300),
        TypographyUtils.convertPixelToEm(120)
      )};
      font-family: bree, sans-serif;
    `,
  h2: `
    ${TypographyUtils.fontSizeCalc(
      TypographyUtils.convertPixelToEm(90),
      TypographyUtils.convertPixelToEm(50)
    )};
    ${TypographyUtils.lineHeightCalc(
      TypographyUtils.convertPixelToEm(99),
      TypographyUtils.convertPixelToEm(60)
    )};
    font-family: bree, sans-serif;
    `,
  h3: `
    ${TypographyUtils.fontSizeCalc(
      TypographyUtils.convertPixelToEm(70),
      TypographyUtils.convertPixelToEm(40)
    )};
    ${TypographyUtils.lineHeightCalc(
      TypographyUtils.convertPixelToEm(78),
      TypographyUtils.convertPixelToEm(48)
    )};
    font-family: bree, sans-serif;
    `,
  h4: `
    ${TypographyUtils.fontSizeCalc(
      TypographyUtils.convertPixelToEm(45),
      TypographyUtils.convertPixelToEm(30)
    )};
    ${TypographyUtils.lineHeightCalc(
      TypographyUtils.convertPixelToEm(50),
      TypographyUtils.convertPixelToEm(35)
    )};
    font-family: bree, sans-serif;
    `,
  h5: `
    ${TypographyUtils.fontSizeCalc(
      TypographyUtils.convertPixelToEm(35),
      TypographyUtils.convertPixelToEm(28)
    )};
    ${TypographyUtils.lineHeightCalc(
      TypographyUtils.convertPixelToEm(42),
      TypographyUtils.convertPixelToEm(33)
    )};
    font-family: bree, sans-serif;
    `,
  title: `
    ${TypographyUtils.fontSizeCalc(
      TypographyUtils.convertPixelToEm(25),
      TypographyUtils.convertPixelToEm(22)
    )};
    ${TypographyUtils.lineHeightCalc(
      TypographyUtils.convertPixelToEm(30),
      TypographyUtils.convertPixelToEm(26)
    )};
    font-family: bree, sans-serif;
    `,
  smallTitle: `
    ${TypographyUtils.fontSizeCalc(
      TypographyUtils.convertPixelToEm(20),
      TypographyUtils.convertPixelToEm(20)
    )};
    ${TypographyUtils.lineHeightCalc(
      TypographyUtils.convertPixelToEm(25),
      TypographyUtils.convertPixelToEm(27)
    )};
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    `,
  body: `
    ${TypographyUtils.fontSizeCalc(
      TypographyUtils.convertPixelToEm(18),
      TypographyUtils.convertPixelToEm(18)
    )};
    ${TypographyUtils.lineHeightCalc(
      TypographyUtils.convertPixelToEm(24),
      TypographyUtils.convertPixelToEm(24)
    )};
    font-family: Open Sans,sans-serif;    
    font-weight: 400;
    `,
  cta: `
    ${TypographyUtils.fontSizeCalc(
      TypographyUtils.convertPixelToEm(18),
      TypographyUtils.convertPixelToEm(18)
    )};
    ${TypographyUtils.lineHeightCalc(
      TypographyUtils.convertPixelToEm(24),
      TypographyUtils.convertPixelToEm(24)
    )};
    font-family: Open Sans,sans-serif;
    font-weight: 600;
    `,
}

export { style }

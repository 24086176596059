let desktop = {
  outerGutter: 80,
  colWidth: 132,
  gutterWidth: 26,
  gridCount: 12,
  pageWidth: 1920,
}

let mobile = {
  outerGutter: 26,
  colWidth: 83,
  gutterWidth: 10,
  gridCount: 4,
  pageWidth: 414,
}

export default {
  desktop: {
    colWidth: `${(desktop.colWidth / desktop.pageWidth) * 100}vw`,
    gutterWidth: `${(desktop.gutterWidth / desktop.pageWidth) * 100}vw`,
    outerGutter: `${(desktop.outerGutter / desktop.pageWidth) * 100}vw`,
    col: (count, exclude) => {
      if (exclude) {
        return `${(((desktop.colWidth + desktop.gutterWidth) * count) /
          desktop.pageWidth) *
          100}vw - ${(desktop.gutterWidth / desktop.pageWidth) * 100}vw`
      } else {
        return `${(((desktop.colWidth + desktop.gutterWidth) * count) /
          desktop.pageWidth) *
          100}vw`
      }
    },
  },
  mobile: {
    colWidth: `${(mobile.colWidth / mobile.pageWidth) * 100}vw`,
    gutterWidth: `${(mobile.gutterWidth / mobile.pageWidth) * 100}vw`,
    outerGutter: `${(mobile.outerGutter / mobile.pageWidth) * 100}vw`,
    col: (count, exclude) => {
      let gutterWidth = exclude ? 0 : mobile.gutterWidth
      return `${(((mobile.colWidth + gutterWidth) * count) / mobile.pageWidth) *
        100}vw`
    },
  },
}

export { desktop, mobile }
